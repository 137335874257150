<template>
  <div class="container">
    <FlashCardsHeader />
    <div class="pageTitle">
      <PageTitle indent>{{ pageTitle }}</PageTitle>
      <template v-if="!pageIsFlashCard && !isFreeMemberAndCollectCard">
        <el-button class="editCollectionBtn" circle @click="openCreateCollection">
          <i class="fas fa-plus" />
        </el-button>
        <el-button
          v-if="editCollectionModeVisible"
          class="editCollectionBtn"
          circle
          @click="finishEditCollection"
        >
          <i class="fas fa-check" />
        </el-button>
        <el-button
          v-else
          class="editCollectionBtn"
          circle
          @click="openEditCollection"
        >
          <i class="fas fa-edit" />
        </el-button>
      </template>
    </div>
    <hr />
    <div v-if="isFreeMemberAndCollectCard" class="hasNoPermission">
      <span class="lock">
        <i class="fas fa-lock" />
      </span>
      <h3 class="upgradeText">
        {{ $t("message.upgrade_to") }}
        <router-link :to="{ name: 'ToeflPlans' }">{{ $t("message.pro_plan") }}</router-link>
        {{ $t("message.to_unlock_this_feature") }}
      </h3>
    </div>
    <template v-else>
      <div class="row">
        <div class="col-md-2 ivy-type-title">
          {{ $t("flashCard.i18n4") }}
        </div>
        <div class="col-sm-12 col-md-10 btn-list">
          <div class="btn-item" @click="() => (currentType = 'card')">
            <label :class="currentType === 'card' ? 'btn active' : 'btn'">
            {{ $t("flashCard.i18n5") }}
            </label>
          </div>
          <div class="btn-item" @click="() => (currentType = 'list')">
            <label :class="currentType === 'list' ? 'btn active' : 'btn'">
            {{ $t("flashCard.i18n6") }}
            </label>
          </div>
        </div>
      </div>
      <div v-if="pageIsFlashCard" class="row">
        <div class="col-md-2 ivy-type-title">
          {{ $t("flashCard.i18n7") }}
        </div>
        <div class="col-sm-12 col-md-10 btn-list">
          <div
            class="btn-item"
            :key="vocabBook.id"
            v-for="vocabBook in vocabBooks"
          >
            <router-link
              :to="{ name: 'Flashcards', query: { vocabBookId: vocabBook.id } }"
            >
              <label
                :class="currentVocabBook === vocabBook.id ? 'btn active' : 'btn'"
              >
                {{ vocabBook.title }}
              </label>
            </router-link>
          </div>
        </div>
      </div>
      <hr />
      <template v-if="pageIsFlashCard">
        <div class="pagination">
          <el-pagination
            @current-change="setPage"
            :page-size="VOCAB_BOOK_PAGE_SIZE"
            :current-page="currentVocabListsPage"
            :total="vocabListsCount"
            :pager-count="5"
            :hide-on-single-page="true"
            layout="prev, pager, next"
          >
          </el-pagination>
        </div>
        <div :key="theVocabList.id" v-for="theVocabList in vocabLists">
          <h3>{{ theVocabList.title }}</h3>
          <div class="row">
            <div
              :key="list.id"
              v-for="list in theVocabList.list"
              class="col-xs-12 col-md-4 col-lg-4"
            >
              <router-link :to="getNextRoute(list.id)">
                <div class="tpoArea tpos">
                  <div class="title">
                    {{ list.title }}
                  </div>
                  <div class="tpoView">
                    <span>{{ $t("flashCard.i18n8") }}</span>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
          <hr />
        </div>
        <div class="pagination">
          <el-pagination
            @current-change="setPage"
            :page-size="VOCAB_BOOK_PAGE_SIZE"
            :current-page="currentVocabListsPage"
            :total="vocabListsCount"
            :pager-count="5"
            :hide-on-single-page="true"
            layout="prev, pager, next"
          >
          </el-pagination>
        </div>
      </template>
      <template v-else>
        <div class="pagination">
          <el-pagination
            @current-change="setPage"
            :page-size="MY_COLLECTION_PAGE_SIZE"
            :current-page="currentVocabListsPage"
            :total="vocabListsCount"
            :pager-count="5"
            :hide-on-single-page="true"
            layout="prev, pager, next"
          >
          </el-pagination>
        </div>
        <div class="row">
          <div
            :key="vocabCollection.id"
            v-for="vocabCollection in vocabCollections"
            class="col-xs-12 col-md-4 col-lg-4"
          >
            <div v-if="editCollectionModeVisible" class="tpoArea editFlashCard">
              <div>{{ vocabCollection.title }}</div>
              <div class="editFlashCardBtns">
                <div
                  class="edit"
                  @click="() => openEditCollectionDialig(vocabCollection)"
                >
                  <span>{{ $t("flashCard.i18n9") }}</span>
                </div>
                <div
                  class="delete"
                  @click="() => deleteCollection(vocabCollection.id)"
                >
                  <span>{{ $t("flashCard.i18n10") }}</span>
                </div>
              </div>
            </div>
            <div v-else>
              <div
                class="tpoArea tpos myFlashTpos"
                @click.stop="() => $router.push(getNextRoute(vocabCollection.id))"
              >
                <div class="title">
                  {{ vocabCollection.title }}
                </div>
                <div class="description">
                  {{ vocabCollection.desc }}
                </div>
                <div class="myFlashCardBtns">
                  <div
                    class="view"
                    @click.stop="() => openMyFlashCardInfo(vocabCollection)"
                  >
                    <span>{{ $t("flashCard.i18n8") }}</span>
                  </div>
                  <div
                    class="practice"
                    @click.stop="() => $router.push(getNextRoute(vocabCollection.id))"
                  >
                    <span>{{ $t("flashCard.i18n11") }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="pagination">
          <el-pagination
            @current-change="setPage"
            :page-size="MY_COLLECTION_PAGE_SIZE"
            :current-page="currentVocabListsPage"
            :total="vocabListsCount"
            :pager-count="5"
            :hide-on-single-page="true"
            layout="prev, pager, next"
          >
          </el-pagination>
        </div>
        <CollectionFormDialog
          v-if="editCollectionDialogVisible"
          :visible="editCollectionDialogVisible"
          :editingCollection="editingCollection"
          @closeNewCollectionDialig="closeEditCollectionDialig"
        />
        <MyFlashCardDialog
          :visible="myFlashCardInfoDialogVisible"
          :myFlashCard="currentMyFlashCard"
          @startPractice="
            () => $router.push(getNextRoute(currentMyFlashCard.id))
          "
          @closeMyFlashCardInfo="closeMyFlashCardInfo"
        />
      </template>
    </template>
  </div>
</template>

<script>
import PageTitle from "@/components/PageTitle.vue";
import FlashCardsHeader from "@/views/toefl/flashcards/FlashCardsHeader.vue";
import CollectionFormDialog from "@/views/toefl/flashcards/CollectionFormDialog.vue";
import MyFlashCardDialog from "@/views/toefl/flashcards/MyFlashCardDialog.vue";

export default {
  metaInfo() {
    return {
      title: `${this.pageTitle} - ` + this.CompanyName
    };
  },
  components: {
    PageTitle,
    FlashCardsHeader,
    CollectionFormDialog,
    MyFlashCardDialog
  },
  computed: {
    pageIsFlashCard() {
      return this.$route.name === "Flashcards";
    },
    isFreeMemberAndCollectCard() {
      return !this.pageIsFlashCard && this.$store.getters["user/isFreeMember"];
    },
    pageTitle() {
      return this.pageIsFlashCard
        ? this.$t("header.flashcards")
        : this.$t("flashcards.myFlashCards");
    },
    VOCAB_BOOK_PAGE_SIZE() {
      return 10;
    },
    MY_COLLECTION_PAGE_SIZE() {
      return 15;
    },
    vocabBooks() {
      return this.$store.getters["flashcard/vocabBooks"];
    },
    vocabListsCount() {
      return this.$store.getters["flashcard/vocabListsCount"];
    },
    currentVocabListsPage() {
      return this.$store.getters["flashcard/currentVocabListsPage"];
    },
    vocabLists() {
      return this.$store.getters["flashcard/vocabLists"];
    },
    vocabList() {
      return this.$store.getters["flashcard/vocabList"];
    },
    vocabCollections() {
      return this.$store.getters["flashcard/vocabCollections"];
    },
    isLogin() {
      return this.$store.getters["user/token"] !== "";
    },
    isPro() {
      return this.$store.getters["user/getIsPro"];
    }
  },
  data() {
    return {
      currentType: "card",
      currentVocabBook: null,
      editCollectionModeVisible: false,
      editCollectionDialogVisible: false,
      editingCollection: null,
      myFlashCardInfoDialogVisible: false,
      currentMyFlashCard: {}
    };
  },
  async created() {
    if (this.pageIsFlashCard) {
      await this.$store.dispatch("flashcard/fetchVocabBooks");
      this.setCurrentVocabBook();
    } else {
      if (this.isLogin && this.isPro) {
        await this.$store.dispatch(
          "flashcard/fetchVocabCollections",
          { page: this.$route.query.page || 1 }
        );
      }
    }
  },
  methods: {
    finishEditCollection() {
      this.editCollectionModeVisible = false;
    },
    openEditCollection() {
      this.editCollectionModeVisible = true;
    },
    openCreateCollection() {
      this.editCollectionDialogVisible = true;
      this.editingCollection = null;
    },
    openEditCollectionDialig(collection) {
      this.editCollectionDialogVisible = true;
      this.editingCollection = { ...collection };
    },
    closeEditCollectionDialig() {
      this.editCollectionDialogVisible = false;
    },
    async deleteCollection(collectionId) {
      try {
        await this.$confirm(
          this.$t("message.areYouSure"),
          this.$t("message.notice"),
          {
            confirmButtonText: this.$t("message.continue"),
            cancelButtonText: this.$t("message.cancel"),
            type: "warning"
          }
        );
      } catch (e) {
        return false;
      }

      await this.$store.dispatch(
        "flashcard/deleteVocabCollection",
        collectionId
      );
      this.$store.dispatch("flashcard/fetchVocabCollections");
      this.$message.success(this.$t("message.delete_success"));
    },
    getNextRoute(vocabListId) {
      const routeName =
        this.currentType === "card"
          ? "FlashcardsVocabulariesCard"
          : "FlashcardsVocabulariesList";
      return {
        name: `${this.pageIsFlashCard ? "" : "My"}${routeName}`,
        query: { listId: vocabListId }
      };
    },
    openMyFlashCardInfo(vocabList) {
      this.myFlashCardInfoDialogVisible = true;
      this.currentMyFlashCard = vocabList;
    },
    closeMyFlashCardInfo() {
      this.myFlashCardInfoDialogVisible = false;
      this.currentMyFlashCard = {};
    },
    setPage(page) {
      this.$router.push({
        ...this.$route,
        query: {
          ...this.$route.query,
          page
        }
      });
    },
    setCurrentVocabBook() {
      if (this.$route.query.vocabBookId) {
        this.currentVocabBook = Number(this.$route.query.vocabBookId);
      } else {
        this.currentVocabBook = this.vocabBooks[0].id;
      }
      this.$store.dispatch("flashcard/fetchVocabBook", {
        vocabBookId: this.currentVocabBook,
        page: this.$route.query.page || 1
      });
    }
  }
};
</script>

<style scoped lang="scss">
.container {
  .pageTitle {
    margin-top: 16px;
    display: flex;
    align-items: center;

    h1 {
      color: var(--themeColor);
    }

    .editCollectionBtn {
      margin-left: 8px;
    font-size: 16px;
      color: var(--themeColor);
    }
  }
}

.hasNoPermission {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #444444;
  .lock {
    font-size: 120px;
  }

  .upgradeText {
    font-size: 32px;
  }
}

.ivy-type-title {
  font-size: 15px;
  font-weight: bold;
  height: 35px;
  line-height: 35px;
  margin: 10px 0;
  text-align: right;
}

.btn-list {
  display: flex;
  justify-content: space-between;
}

.btn-item {
  width: 100%;
  margin: 0px 20px;
}

.btn {
  width: 100%;
  height: 35px;
  line-height: 35px;
  cursor: pointer;
  background-color: #f5f5f5;
  color: var(--themeColor);
  text-align: center;
  border-radius: 5px;
  padding: 0 5px;
  margin: 10px 0;
}

.btn:hover,
.active {
  color: white;
  background: var(--themeColor);
  box-shadow: 1px 3px 6px #aaa;
}

.tpoArea {
  margin: 10px 0;
  border-radius: 10px;
  height: 80px;
  box-sizing: border-box;
  background-color: #edf1f8;
  border-bottom: 2px solid #dadee5;
  color: #808080;
  transition: 0.2s 0s;
  border-radius: 5px;
  padding: 8px;
}
.editFlashCard {
  text-align: center;
}

.editFlashCardBtns {
  margin-top: 4px;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    cursor: pointer;
    font-size: 14px;
    border-radius: 50em;
    color: #fff;
    background-color: var(--themeColor);
    padding: 4px 24px;
    margin: 0px 4px;
  }

  .delete {
    span {
      background-color: #ff5252;
    }
  }

  span:hover {
    opacity: 0.7;
  }
}

.myFlashCardBtns {
  .view, .practice {
    display: none;
    margin: 0px 4px;
    cursor: pointer;

    span {
      font-size: 14px;
      border-radius: 50em;
      color: #fff;
      background-color: var(--themeColor);
      padding: 4px 24px;
    }
  }

  .view:hover, .practice:hover {
    opacity: 0.7;
  }
}

.tpos {
  cursor: pointer;

  .title {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .tpoView {
    display: none;

    span {
      font-size: 14px;
      border-radius: 50em;
      color: #fff;
      background-color: var(--themeColor);
      padding: 4px 24px;
    }
  }
}

.tpos:hover {
  box-shadow: 3px 5px 10px #aaa;

  .title {
    display: none;
  }

  .tpoView {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .myFlashCardBtns {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    .view, .practice {
      display: block;
    }
  }
}

.myFlashTpos {
  height: 100px;

  .title {
    margin-top: 4px;
    padding-bottom: 4px;
    box-sizing: content-box;
    height: 30px;
    font-weight: bold;
    align-items: flex-start;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
  }

  .description {
    width: 100%;
    height: 50px;
    padding: 12px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.myFlashTpos:hover {
  .description {
    display: none;
  }
}

.pagination {
  display: flex;
  justify-content: center;
  margin: 12px 0px;
}

@media screen and (max-width: 992px) {
  .ivy-type-title {
    text-align: left;
  }
}
</style>
