<template>
  <el-dialog
    :title="`${$t('flashCard.i18n28')}${myFlashCard.title}`"
    :visible.sync="visible"
    width="400px"
  >
    <div><b>{{ $t("flashCard.i18n29") }}</b>{{ myFlashCard.desc }}</div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="$emit('closeMyFlashCardInfo')">
        {{ $t("flashCard.i18n30") }}
      </el-button>
      <el-button type="primary" @click="$emit('startPractice')">
        {{ $t("flashCard.i18n31") }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    visible: {
      default: false,
      type: Boolean
    },
    myFlashCard: {
      default: () => ({}),
      type: Object
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .el-dialog__body {
  padding: 0px 20px;
  font-size: 16px;
}

::v-deep .el-form-item {
  margin-bottom: 8px;
}
</style>
