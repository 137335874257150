<template>
  <el-menu
    :default-active="activeTabName"
    class="el-menu-demo"
    mode="horizontal"
  >
    <el-menu-item index="Flashcards">
      <b>
        <router-link :to="{ name: 'Flashcards' }" class="linkInTab">
          {{ $t("header.flashcards") }}
        </router-link>
      </b>
    </el-menu-item>
    <el-tooltip
      v-if="!isLogin || isFreeMember"
      class="item"
      effect="dark"
      :content="$t('toefl.Pro Plans')"
      placement="top"
    >
      <el-menu-item disabled index="MyFlashcards">
        <b class="proFeatureTitle">
          <i class="fas fa-lock" />
          {{ $t("flashcards.myFlashCards") }}
        </b>
      </el-menu-item>
    </el-tooltip>
    <el-menu-item v-else index="MyFlashcards">
      <b class="proFeatureTitle">
        <i class="fas fa-unlock" />
        <router-link :to="{ name: 'MyFlashcards' }" class="linkInTab">
          {{ $t("flashcards.myFlashCards") }}
        </router-link>
      </b>
    </el-menu-item>
  </el-menu>
</template>

<script>
export default {
  computed: {
    activeTabName() {
      return this.$route.name;
    },
    isLogin() {
      return this.$store.getters["user/token"] !== "";
    },
    isFreeMember() {
      return this.$store.getters["user/isFreeMember"];
    }
  }
};
</script>

<style lang="scss" scoped>
.linkInTab::after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: "";
}

.linkInTab {
  color: inherit !important;
}

.linkInTab:hover {
  text-decoration: none;
}

.menu-item {
  position: relative;
}

.proFeatureTitle {
  display: flex;
  align-items: center;

  svg {
    margin: 0px 8px;
  }
}
</style>
