<template>
  <el-dialog
    :title="editingCollection ? $t('flashCard.i18n2') : $t('flashCard.i18n1')"
    :visible.sync="visible"
    width="400px"
  >
    <el-form ref="form" :model="collection">
      <el-form-item label="Title:" prop="title" :rules="[commonRules.input]">
        <el-input v-model="collection.title" />
      </el-form-item>
      <el-form-item
        label="Description:"
        prop="description"
        :rules="[commonRules.input]"
      >
        <el-input type="textarea" :rows="2" v-model="collection.description" />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeNewCollectionDialig">
        {{ $t("alert.cancel") }}
      </el-button>
      <el-button type="primary" @click="createCollection">
        {{ $t("alert.save") }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import formMixin from "@/mixins/form";

export default {
  props: {
    visible: {
      default: false,
      type: Boolean
    },
    editingCollection: {
      default: null,
      type: Object
    }
  },
  mixins: [formMixin],
  computed: {
    isFreeMember() {
      return this.$store.getters["user/isFreeMember"];
    },
    vocabCollections() {
      return this.$store.getters["flashcard/vocabCollections"];
    }
  },
  watch: {
    editingCollection(newEditingCollection) {
      this.collection = {
        title: newEditingCollection.title,
        description: newEditingCollection.desc
      };
    }
  },
  data() {
    return {
      collection: { title: "", description: "" }
    };
  },
  methods: {
    closeNewCollectionDialig() {
      this.$emit("closeNewCollectionDialig");
      this.collection = { title: "", description: "" };
    },
    async createCollection() {
      const valid = await this.validateForm(this.$refs["form"]);
      if (!valid) {
        this.$message.error(this.$t("message.form_validation_error"));
        return;
      }

      try {
        if (this.editingCollection) {
          await this.$store.dispatch(
            "flashcard/updateVocabCollection", {
              collectionId: this.editingCollection.id,
              ...this.collection
            }
          );
          this.$message.success(this.$t("message.update_success"));
        } else {
          await this.$store.dispatch(
            "flashcard/createVocabCollection",
            this.collection
          );
          this.$message.success(this.$t("message.create_success"));
        }
        this.$store.dispatch(
          "flashcard/fetchVocabCollections",
          { page: this.$route.query.page || 1 }
        );
        this.collection = { title: "", description: "" };
        this.$emit("closeNewCollectionDialig");
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .el-dialog__body {
  padding: 0px 20px;
}

::v-deep .el-form-item {
  margin-bottom: 8px;
}
</style>
